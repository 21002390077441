import { Injectable } from '@angular/core';
import { RootAPIService } from 'src/app/shared/rootService/rootApiService';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { DepartmentIndentApprovalAuthoritiesList, ApprovalSave,ReminderByEmail, RequesterNotification, RequesterAction, ApprovalByEmail, RequesterSaveOrDateException } from '../department-indent-model/department-indent.model';
import { DepartmentIndent } from '../../indent-draft/create-indent-draft/create-indent-draft-model/create-indent-draft.model';
import { DaisResponse } from 'src/app/shared/commonService/common.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentIndentService {

  constructor(private _rootApiService: RootAPIService) { }

  getUserDepartmentList(userId: string): Observable<DepartmentIndent[]> {
    const httpParams = new HttpParams()
      .set('userId', userId)
    return this._rootApiService.GetData<DepartmentIndent[]>('master/getUserDepartment', httpParams)
  }

  /**
   * fetching the department data list based on user's pending
   * @param userId 
   */
  getUserApprovalDepartment(userId: string): Observable<DepartmentIndent[]> {
    const httpParams = new HttpParams()
      .set('userId', userId)
    return this._rootApiService.GetData<DepartmentIndent[]>('indentInfo/getUserApprovalDept', httpParams)
  }

  getDepartmentIndentApprovalAuthList(deptId: string, userId: string): Observable<DepartmentIndentApprovalAuthoritiesList[]> {
    const httpParams = new HttpParams()
    .set('deptId', deptId)
    .set('userId', userId);
    return this._rootApiService.GetData<DepartmentIndentApprovalAuthoritiesList[]>('indentInfo/getApprovalsList', httpParams);
  }

  getDepartmentIndentRequestor(deptId: string, emailId: string): Observable<DepartmentIndentApprovalAuthoritiesList[]> {
    const httpParams = new HttpParams()
    .set('deptId', deptId)
    .set('emailId', emailId);
    return this._rootApiService.GetData<DepartmentIndentApprovalAuthoritiesList[]>('indentInfo/getRequester', httpParams);
  }

  /**
   * Save approval in department indent
   * @param approvalSave 
   */
  saveApproval(approvalSave: ApprovalSave[]): Observable<DaisResponse>{
    return this._rootApiService.PostData<DaisResponse, ApprovalSave[]>('indentInfo/deptIndentApprovals', approvalSave);
  }

  pushItemToPMSOnDE(action: string): Observable<DaisResponse>{
      return this._rootApiService.PostData<DaisResponse,string>('indentInfo/pushIndentToPMS',action);
  }

  saveRequester(requesterSave: RequesterSaveOrDateException): Observable<DaisResponse>{

    return this._rootApiService.PostData<DaisResponse, RequesterSaveOrDateException>('indentInfo/requester/save', requesterSave);
  }

  getRequesterNotification(clientId: string, emailId:string, isCount: string): Observable<RequesterNotification[]>{
    const httpParams = new HttpParams()
    .set('clientId', clientId)
    .set('emailId', emailId)
    .set('isCount', isCount);
    return this._rootApiService.GetData<RequesterNotification[]>('notifications/getNotifications', httpParams);
  }

  /**
   * action taken by requester in notification screen
   * @param requesterAction 
   */
  requesterAction(requesterAction: RequesterAction): Observable<DaisResponse>{
    return this._rootApiService.PostData<DaisResponse, RequesterAction>('notifications/requester/action', requesterAction);
  }

  /**
   * action taken by requester for date exception
   * @param saveForDateException 
   */
  dateExceptionSave(saveForDateException: RequesterSaveOrDateException){
    return this._rootApiService.PostData<DaisResponse, RequesterSaveOrDateException>('indentInfo/requester/deApproval', saveForDateException);
  }

    /**
   * Save approval from email in department indent
   * @param approvalbyEmail 
   */
  saveApprovalByEmail(approvalbyEmail: ApprovalByEmail): Observable<DaisResponse>{
    return this._rootApiService.PostData<DaisResponse, ApprovalByEmail>('indentInfo/approveIndent', approvalbyEmail);
  }
    /**
   * Save approval from email in department indent
   * @param approvalbyEmail
   */
  sendReminderMail(approvalByEmail: ApprovalByEmail): Observable<DaisResponse>{
       return this._rootApiService.PostData<DaisResponse,ApprovalByEmail>(`indentInfo/reminderApproveByMail`, approvalByEmail);
  }

  /**
     * Save approval from email in department indent
     * @param approvalbyEmail
     */
    sendReminderMailByDept(reminderByEmail: ReminderByEmail): Observable<DaisResponse>{
         return this._rootApiService.PostData<DaisResponse,ReminderByEmail>(`indentInfo/reminderApproveByDeptMail`, reminderByEmail);
    }

}
 