export class DepartmentLedgerMapping {
    id: number;
    deptId: number; 
    itemId: number;
    itemName: string;
    ledgerId: number;
    ledgerName: string;
    purpose: string;
    purposeId: number;
    status: string;
    modifiedBy: string;
    createDate: string;
}