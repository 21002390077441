import { Injectable } from '@angular/core';
import { RootAPIService } from 'src/app/shared/rootService/rootApiService';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { DepartmentLedgerMapping } from '../department-ledger-model/department-ledger.model';
import { DaisResponse } from 'src/app/shared/commonService/common.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentLedgerService {

  constructor(private _rootApiService: RootAPIService) { }

  getDepartmentLedgerMappingList(deptId: string): Observable<DepartmentLedgerMapping[]> {
    const httpParams = new HttpParams()
    .set('deptId', deptId);
    return this._rootApiService.GetData<DepartmentLedgerMapping[]>('master/deptLedgerList', httpParams)
  }

  saveDepartmentLedgerMapping(departmentLedgerMap: DepartmentLedgerMapping): Observable<DaisResponse>{
    return this._rootApiService.PostData<DaisResponse, DepartmentLedgerMapping>('dept-ledger-mappings', departmentLedgerMap);
  }

  updateDepartmentLedgerMapping(departmentLedgerMap: DepartmentLedgerMapping): Observable<DaisResponse>{
    return this._rootApiService.PutData<DaisResponse, DepartmentLedgerMapping>('dept-ledger-mappings', departmentLedgerMap);
  }
}
