import { AbstractControl } from '@angular/forms';

export function ValidateApproximatePrice(control: AbstractControl) {
  if (control.value === '0' || control.value === '0.0' || control.value === '00' || control.value === 0 || control.value === null || control.value === '' || +control.value <= 0  ) {
    return { errorMessage: 'Approximate Price cannot not be empty or 0'};
  }
  return null;
}

export function ValidateBudgetDayofMonth(control: AbstractControl) {
  if(control.value === 0 || control.value === null) {
    return { errorMessage: 'Required field' };
  }
  return null;
}

export function ValidateEmail(control: AbstractControl) {
  //if(control.)
}