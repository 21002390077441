export class ActiveInactive {
    processId: number;
    processStatusValue: string;
    processStatus: string;
}
export class YesOrNo {
    isUnique: number;
    isUniqueVal: string;

}

export interface DaisResponse {
    result: string;
    resultId: string;
    lstResultId: string[];
    message: string;
}

export interface PurchaseResponse {
    requestId: string;
    error: string;
    returnStatus: number;
    result: string;
    message: string;
}

export class LedgerDetails {
    ledgerId: number;
    ledgerName: string;
}

export class DepartmentDetails {
    deptId: number;
    deptName: string;
}

export class PurposeDetails {
    purposeId: number;
    purposeName: string;
}

export class EntityDetails {
    entityId: number;
    entityName: string;
}

export class BudgetDetails {
    budgetId: number;
    budgetCode: string;
}

export class CategoryDetails {
    categoryId: number;
    categoryName: string;
}

export class UserNavigation {
    userRoles: NavigationLink[];
}

export class NavigationLink {
    name: string;  //Name of the menu
    desc: string; //about the menu 
    icon: string; //icon of the menu
    children: NavigationLinkChild[];
}

export class NavigationLinkChild {
    name: string;
    desc: string;
    routerLink: string;
    icon: string;
}

export class PurchaseErrorResponse {
    code: number;
    message: string;
    detailedMessage: string;
    ishaModule: string;
}

export class AttachmentDetailsResponse {
    amount: number;
    apiLogId: string;
    fileType: string;
    imageName: string;
    imageType: string;
    img: string;
    modifiedDate: string;
    pageNumber: number;
    remarks: string;
}